<template>
  <div class="product-evaluation-container">
    <header-page-derek
      :title="'Valoración de producto'"
      :description="
        'Tu opinión es muy importante, cada vez que reseñas un producto ayudas a más usuarios a tomar una decisión, por eso te pedimos que NO tengas en cuentas aspectos como, facilidad en la compra, tiempo de entrega, servicio al cliente o disponibilidad, enfoca tu opinión en la calidad y prestaciones del producto; no olvides escribir sobre la silueta, el tamaño y la comodidad. Todas las opiniones son revisadas por nuestro equipo antes de ser publicadas'
      "
      :skeleton="false"
    />
    <div v-if="dataProduct" class="product-container-evaluation">
      <div class="mx-auto">
        <div class="d-flex">
          <img :src="dataProduct.referencias[0].imagenes[0]" />
          <div class="ps-3">
            <router-link
              :to="{
                name: 'ProductDetail',
                params: { product: dataProduct.url.replace('product-', '') },
              }"
            >
              <p class="title-product-evaluation">{{ dataProduct.titulo }}</p>
            </router-link>
            <span class="data-product-evaluation">
              SKU: {{ dataProduct.producto_sku }}<br />
              Color: {{ dataProduct.referencias[0].color_nombre }}
            </span>
            <p>
              ${{ new Intl.NumberFormat('de-DE').format(dataProduct.precio) }}
            </p>
          </div>
        </div>
        <div v-if="!sended" class="w-100 my-5 d-flex justify-content-center">
          <div class="send-comment w-100">
            <div class="box-cal-message">
              <p
                class="fs-6 text-center mb-2"
                :class="validateCal ? 'msgError' : ''"
              >
                Selecciona tu calificación
              </p>
              <p class="fs-5 text-center position-relative">
                <b-form-rating
                  id="rating-inline"
                  v-model="cal"
                  inline
                  :style="
                    missingCal
                      ? `animation-name: missingSize; animation-duration: .5s;`
                      : ''
                  "
                  :value="cal"
                  variant="warning"
                  class=""
                  size="lg"
                  no-border
                />
                <strong> {{ cal }} </strong>
              </p>
            </div>
            <div v-if="!token" class="w-100 mb-4">
              <div class="w-100 position-relative mb-4">
                <label for="input-email">Correo: </label>
                <input
                  id="email"
                  v-model="email"
                  class="payment-input mb-3 w-100"
                  type="email"
                />
                <span
                  v-if="validateEmail"
                  class="msgError"
                  style="position: absolute; bottom: -1.3rem; right: 0"
                  >Este campo es obligatorio</span
                >
              </div>
              <div class="w-100 position-relative">
                <label for="input-name">Nombre: </label>
                <input
                  id="input-name"
                  v-model="name"
                  class="payment-input mb-3 w-100"
                  type="given-name"
                  autocomplete="given-name"
                />
                <span
                  v-if="validateName"
                  class="msgError"
                  style="position: absolute; bottom: -1.3rem; right: 0"
                  >Este campo es obligatorio</span
                >
              </div>
            </div>
            <div class="w-100 position-relative">
              <textarea
                v-model="opinion"
                rows="5"
                class="w-100 p-2"
                placeholder="Escribe una frase o tú opinión sobre el producto."
              ></textarea>
              <span
                v-if="validateOpinion"
                class="msgError"
                style="position: absolute; bottom: -1.3rem; right: 0"
                >Este campo es obligatorio, mínimo 20 caracteres.</span
              >
            </div>
            <div class="mt-5 w-100 text-center">
              <button class="btn-primary w-50" @click="sendMessage()">
                Enviar opinión
              </button>
            </div>
          </div>
        </div>
        <div v-else class="pt-4">
          <div class="my-5 msgError">
            <div class="text-center">
              <h3>Gracias por tu opinión</h3>
              <p class="fs-6" style="color: black">
                Hemos registrado tu comentario, estaremos evaluando el
                contenido.
              </p>
              <router-link
                :to="{
                  name: 'ProductDetail',
                  params: { product: dataProduct.url.replace('product-', '') },
                }"
              >
                <button class="btn-primary px-4 mt-3 fs-6">
                  Volver al producto
                </button>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import HeaderPageDerek from '@/components/Templates/HeaderPageDerek'

import { mapState, mapActions } from 'vuex'
export default {
  components: { HeaderPageDerek },
  data() {
    return {
      cal: null,
      email: '',
      name: '',
      opinion: '',
      token: null,
      validateEmail: false,
      validateName: false,
      validateOpinion: false,
      validateCal: false,
      missingCal: false,
      sended: false,
    }
  },
  computed: {
    ...mapState('products', ['dataProduct', 'errorSendComment']),
    ...mapState('user', ['dataUser']),
    /* eslint-disable */
    validateEmailOpinion: function() {
      const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(this.email)
    },
  },
  watch: {
    dataUser() {
      if (this.dataUser) {
        this.email = this.dataUser.persona.correo
        this.name =
          this.dataUser.persona.nombres + ' ' + this.dataUser.persona.apellidos
        this.token = window.localStorage.getItem('accessToken')
      }
    },
  },
  async mounted() {
    this.token = window.localStorage.getItem('accessToken')
    if (this.dataUser && window.localStorage.getItem('accessToken')) {
      this.email = this.dataUser.persona.correo
      this.name =
        this.dataUser.persona.nombres + ' ' + this.dataUser.persona.apellidos
    }
    if (
      !(this.dataProduct && this.dataProduct?.url == this.$route.params.url)
    ) {
      await this.getDataProduct({ url: 'product-' + this.$route.params.url })
    }
    // this.$gtag.pageview(this.$route.fullPath)
  },
  methods: {
    ...mapActions('products', ['getDataProduct', 'sendComment']),
    async sendMessage() {
      if (
        this.validateEmailOpinion &&
        this.name.length > 3 &&
        this.opinion.length >= 20 &&
        this.cal
      ) {
        this.validateEmail = false
        this.validateName = false
        this.validateOpinion = false
        this.validateCal = false
        this.$recaptchaLoaded().then(async () => {
          this.$recaptcha('login').then(async token => {
            if (token) {
              await this.sendComment({
                cal: this.cal,
                text: this.opinion,
                producto_sku: this.dataProduct.producto_sku,
                email: this.email,
                name: this.name,
              }).then(() => {
                if (!this.errorSendComment) {
                  this.sended = true
                }
              })
            }
          })
        })
        console.log('Puedo enviar opinion')
        console.log(this.email, this.name, this.opinion)
      } else {
        this.validate()
      }
    },
    async validate() {
      this.validateEmail = !this.validateEmailOpinion
      this.validateName = !(this.name.length > 3)
      this.validateOpinion = !(this.opinion.length >= 20)
      this.validateCal = !this.cal
      if (!this.cal) {
        this.missingCal = true
        await setTimeout(() => {
          this.missingCal = false
        }, 1000)
      }
    },
  },
}
</script>
